@use "./variables.scss" as vars;

.products-item-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1em;
  justify-items: center;
  padding: 0 5%;
  margin-bottom: 5px;

  .MuiCard-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
  }

  .card-cart,
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 345px;
    min-width: 250px;
  }

  .MuiCard-root:hover {
    transition: 1s;
  }

  .MuiCardMedia-root,
  .card-image {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .img-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1; /* Allow this section to grow and fill available space */

    .img-description {
      padding: 10px;
      color: black; /* Adjusted from transparent */
      background-color: #f5f5f5; /* Light grey background for description */
    }
  }

  .bottom-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    background-color: white;
    border-top: 1px solid #ddd;

    .price,
    .shipment-cost {
      color: #019ace;
      font-weight: bold;
      margin-right: 5px;
    }

    .bottom-bar-price {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      .price,
      .shipment-cost {
        color: #019ace;
        font-weight: bold;
        margin-right: 5px;
      }

      .shipment-cost {
        font-size: 0.9rem; /* Slightly smaller than the price */
        color: #6a6a6a; /* Grey color for shipment cost */
      }

      .action-button {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .card-cart {
      display: flex;
      flex-direction: column;
      height: 300px; // Ajusta según tus necesidades
      overflow: hidden;
      margin-bottom: 16px;
      position: relative;
      border: 1px solid #ddd; // Opcional: Añadir borde para mejor visualización
    }

    .card-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 16px;
      overflow: hidden; // Asegura que el contenido no se desborde
    }

    .card-name,
    .card-category {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card-image {
      width: 100%;
      height: 200px; // Ajusta la altura según tus necesidades
      object-fit: cover;
    }

    .bottom-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-top: 1px solid #ddd; // Opcional: Añadir borde para separar el contenido
    }
  }

  .badge-container {
    margin-left: 90%;
  }

  .badge-empty {
    background-color: white !important;
  }

  .card-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem; /* Ajusta el tamaño del texto según necesites */
    font-weight: bold;
    line-height: 1.2; /* Ajusta la altura de la línea */
    max-height: 3.6rem; /* Limita la altura del título a tres líneas */
    overflow: hidden; /* Oculta el contenido excedente */
    text-overflow: ellipsis; /* Añade puntos suspensivos si el texto es muy largo */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Propiedad específica de WebKit para limitar a dos líneas */
    -webkit-box-orient: vertical; /* Mantener la orientación del contenido en vertical */
    display: box; /* Fallback genérico */
    line-clamp: 3; /* Propiedad estándar */
    box-orient: vertical; /* Propiedad estándar para compatibilidad */
    white-space: normal; /* Permite que el texto haga salto de línea */
    text-align: center;
    height: 4rem;
  }

  .card-category {
    color: gray;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 1700px) {
  .products-item-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .products-item-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1000px) {
  .products-item-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .products-item-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.MuiSvgIcon-root {
  font-size: large;
  padding: 0px !important;
}

.MuiCardHeader-action,
.MuiCardActions-root {
  .MuiTypography-root {
    color: #019ace !important;
  }
}

.MuiCardContent-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px !important;
}

.flex-container {
  display: flex;
  padding-left: 5%;
}

.font-size-medium {
  font-size: medium !important;
  padding: 0px !important;
  padding-left: 4px !important;
}

.products-item-wrapper.flex-container {
  display: grid; /* Ensure that the grid display is used */
  padding: 8px;
  padding-top: 12px;
}

.product-item {
  width: 300px; /* Set a fixed width for the card */
  margin: 0 auto; /* Center the card horizontally */
  display: inline-block; /* Align cards next to each other */
  vertical-align: top; /* Align all cards at the top */
}

.product-item-container {
  text-align: center; /* Center all cards within the container */
}

.shipment-card {
  width: 250px; /* Coincide con la clase .product-item */
  margin: 0 auto; /* Centrar la tarjeta horizontalmente */
  display: inline-block; /* Para alinear junto a otras tarjetas */
  vertical-align: top; /* Alinear todas las tarjetas en la parte superior */
}

/* CSS for the toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* EMAIL LINK CONTRAST */

.email-link {
  color: vars.$azul-galicia; /* Change text color */
  background-color: transparent; /* Remove background */
  text-decoration: underline; /* Optional underline */
}
