@use "./variables.scss" as vars;

.contact-page-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: calc(66vh - 50px);
  padding-top: 30px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  .contact-left-column {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .contact-right-column {
    display: flex;
    align-items: center;
    padding: 80px 100px 80px 100px;

    @media (max-width: 960px) {
      padding: 50px;
    }

    .category-side-title {
      color: #999999;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .contact-bullet-points {
      display: grid;
      grid-gap: 21px;

      .bullet-point-group {
        display: flex;
        align-items: center;

        .icon {
          font-size: 2em;
          color: #999999;
        }

        .text {
          font-size: 1.5em;
          font-weight: 900;
          margin-left: 21px;
          color: #999999;
          a {
            color: #999999;
            &:hover {
              color: vars.$azul-galicia;
            }
          }
        }
      }
    }
  }
}
