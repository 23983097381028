@use "./variables.scss" as vars;

.cart-page-wrapper {
  //display: grid;
  //grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  //grid-gap: 10px;
  justify-items: center;
  color: white;
  //font-weight: bold;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 845px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; // `align-items` already centers items horizontally
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0%;
  }

  .payment-buttons {
    margin-right: 5%;

    @media (max-width: 845px) {
      margin-right: 0%;
    }
  }

  .cart-title {
    //columns 1st to 3rd (all), 2st row
    //grid-column: 1 / 4;
    //grid-row: 2;
    //height: 100%;
    font-size: 1.6rem;
    padding-bottom: 30px;
    word-spacing: 0.15rem;

    @media (max-width: 845px) {
      font-size: 1.3rem;
      padding-bottom: 5px;
    }
  }

  .lottie-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 35%;
    margin-top: 50px;

    @media (max-width: 1115px) {
      margin-left: 15%;
      margin-top: 55px;
    }
    @media (max-width: 845px) {
      margin-left: 5%;
      margin-top: 0px;
    }
    @media (max-width: 600px) {
      margin-left: 0%;
    }
    @media (max-width: 550px) {
      margin-left: 0%;
    }

    img {
      @media (max-width: 845px) {
        height: 260px;
        width: 260px;
      }
      @media (max-width: 600px) {
        height: 200px;
        width: 200px;
      }
    }
  }

  .lottie {
    //columns 1st & 2nd, 1st & 3rd row
    grid-column: 1 / 4;
    grid-row: 3;
    display: flex;
    justify-content: flex-end;
  }

  //.cart-products {
  //columns 1st & 2nd, 1st & 3rd row
  //grid-column: 1 / 3;
  //grid-row: 3;
  //}

  .cart-total {
    //3rd column, 3rd row
    grid-column: 3 / 4;
    grid-row: 3;
    font-size: large;
    color: white;

    a {
      text-decoration: none;
    }
  }
  .cart-total {
    @media (max-width: 845px) {
      margin-right: 10px;
    }
  }

  .cart-checkout {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background-color: vars.$azul-galicia;
    border-radius: 10px;
    padding: 20px;
    font-size: small;

    @media (max-width: 845px) {
      margin-top: 10px;
    }
  }

  .cart-checkout {
    display: flex;
    align-items: center; // Alinea verticalmente el icono y el texto
    justify-content: center; // Centra el contenido horizontalmente
    gap: 8px; // Espacio entre el icono y el texto

    .MuiButton-startIcon {
      margin-right: 8px; // Espacio entre el icono y el texto
    }

    svg {
      vertical-align: middle; // Alinea verticalmente el SVG si es necesario
    }
  }
}

.MuiBadge-colorSecondary {
  background-color: vars.$azul-galicia !important;
}

/*Texto del Modal*/
.dialogo {
  .color-negro {
    color: black !important;
  }
}
