@use "./variables.scss" as vars;

.gestion-portfolio-wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;

  .left-column {
    background-color: vars.$offwhite;
    margin-left: 5%;

    @media (max-width: 610px) {
      margin-left: 0%;
    }
  }

  .right-column {
    background-color: vars.$charcoal;
    margin-right: 10%;
  }
}
