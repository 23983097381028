@use "./variables.scss" as vars;

/* COMENTARIOS EXISTENTES */

.comentarios-container {
  margin-top: 20px;
  h3 {
    color: vars.$azul-galicia !important;
  }
}

.comentario-card {
  background-color: #e7e6e6; /* Un gris claro */
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 4px 15px;
  margin-bottom: 15px;
  word-wrap: break-word; /* Romper palabras largas */
  overflow-wrap: break-word; /* Asegurar ajuste de texto */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.comentario-header {
  p {
    color: #8d8d8d !important;
    margin: 0px;
  }
}

.comentario-fecha {
  color: #999;
  font-size: 14px;
}

.comentario-contenido {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  word-wrap: break-word; /* Forzar ajuste de palabras */
  overflow-wrap: break-word; /* Compatibilidad adicional */
  max-width: 100%; /* Asegurar que no exceda el contenedor */
  line-height: 1.5;
  p {
    color: #8d8d8d !important;
    //margin-bottom: 2px;
    padding: 0px;
    margin: 0px;
  }
}

.comentario-acciones {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-actions {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-bottom: 5px;

  @media (max-width: 690px) {
    flex-direction: column;
  }

  button {
    max-width: 200px;
    height: 40px; /* Define una altura fija */
    margin-left: 10px;
    margin-right: 10px;
  }

}

.btn-responder,
.btn-eliminar {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 90%;
}

.btn-responder {
  background: #28a745;
  color: white;
}

.btn-eliminar {
  background: #dc3545;
  color: white;
}

.respuesta {
  margin-left: 10px;
  padding: 0px 10px;
  border-left: 2px solid #a9a9a9; /* Verde para diferenciar */
  background-color: #e7e6e6; /* Un gris claro */
  color: #3a3a3a;
  p {
    color: #656565 !important;
  }
}

.acciones-admin {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.btn-responder {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-eliminar {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.input-respuesta {
  height: auto;
  padding: 10px; /* Espaciado interno */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  resize: none; /* Evita que se pueda redimensionar manualmente */
  overflow-y: auto; /* Muestra scroll solo si es necesario */
}

.input-respuesta:focus {
  outline: none;
  border-color: #007bff; /* Azul al enfocar */
}

/* ENTRADA DE COMENTARIO */

.comentario-input-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f8ff;
  border: 1px solid #cce7ff;
  border-radius: 10px;

}

.comentario-textarea {
  height: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  overflow-y: auto;
  width: -webkit-fill-available;
}

.comentario-textarea:focus {
  outline: none;
  border-color: #007bff;
}

.comentario-botones {
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.btn-publicar {
  background-color: #28a745;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-publicar:hover {
  background-color: #218838;
}

.btn-suscribete {
  background-color: rgb(77, 77, 255);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.btn-suscribete:hover {
  background-color: blue;
}

.info-icon {
  font-size: 16px;
  color: #6c757d;
  cursor: pointer;
}

.info-icon:hover {
  color: #495057;
}

.privacidad-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #6c757d !important;
  .privacidad {
    color: #6c757d !important;
  }
  .boton-footer {
    color: #6c757d !important;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.comentario-input-container {
  .modal {
    .modal-content h2,
    p {
      margin-bottom: 10px;
      color: black;
    }
  }
}

.btn-cerrar-modal {
  background-color: #dc3545;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-cerrar-modal:hover {
  background-color: #c82333;
}

.newsletter-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.btn-cerrar-newsletter {
  background-color: #6c757d;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-cerrar-newsletter:hover {
  background-color: #5a6268;
}

.editado-badge {
  color: vars.$azul-galicia;
  font-size: 0.9em;
  font-weight: bold;
  margin-left: 10px;
}

.btn-editar, .btn-guardar {
  background-color: transparent;
  border: 2px solid vars.$azul-galicia; /* Color naranja para destacar */
  color: vars.$azul-galicia; /* Texto en color naranja */
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-editar:hover, .btn-guardar:hover {
  background-color: vars.$azul-galicia;
  color: white;
  transform: scale(1.05);
}

.btn-editar:active, .btn-guardar:active {
  transform: scale(0.95);
}

.btn-editar svg, .btn-guardar svg {
  width: 16px;
  height: 16px;
}


.btn-publicar,
.btn-suscribete {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px; /* Ajustar el padding */
  font-size: 16px; /* Mantener un tamaño uniforme */
  min-height: 44px; /* Establecer una altura mínima */
  border-radius: 6px; /* Asegurar bordes consistentes */
  text-align: center;
}
