.blog-manager-wrapper {
    background-color: whitesmoke;
    padding: 2%;
    margin-right: 5%;
    h5 {
        color: #8a8a8a !important;
    }

    .editor-nueva-entrada {
        .DraftEditor-root {
            padding: 0px;
        }
    }

}