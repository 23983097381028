@use "./variables.scss" as vars;

.boton-footer {
  color: white;
  font-size: small;
  cursor: pointer;

  &:hover {
    color: vars.$azul-galicia;
  }
}
