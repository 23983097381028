@use "./variables.scss" as vars;

.newsletter {
  font-family: Arial, sans-serif;
  color: white;
  background-color: black;
  padding-right: 15%;
  padding-left: 15%;

  .titulo {
    display: flex;
    justify-content: space-between;
    margin-top: 0.2rem;
    align-items: flex-start; /* Alinea los elementos al inicio del eje vertical */

    .rotated-arrow {
      transform: rotate(315deg);
      font-size: 0.8em;
      margin-top: 5px; /* Ajusta la posición para alinearse mejor con el texto */
      margin-left: 2px;
    }
  }
}

.newsletter-header {
  text-align: center;
}

.newsletter-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.newsletter-header p {
  margin-bottom: 1rem;
}

.newsletter-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 960px) {
    flex-direction: column;
  }

}

.newsletter-form input {
  width: 80%;
  max-width: 400px;
  margin: 0.5rem 0.4rem;
  padding: 0.75rem;
  font-size: 1rem;
}

/*
.newsletter-form button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0rem 0.5rem;
}

.newsletter-form button:hover {
  background-color: #0056b3;
}
*/

.privacy-policy {
  font-size: 0.85rem;
  margin-top: 1rem;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.latest-posts {
  margin-top: 2rem;
}

.latest-posts h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.post {
  background-color: #222;
  padding: 1rem;
  border-radius: 8px;
}

.post p {
  margin: 0.5rem 0;
}

.post h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;

  a {
    color: vars.$azul-galicia;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  color: black;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.btn-accept {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn-accept:hover {
  background-color: #0056b3;
}

.btn-subscribe {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.btn-subscribe:hover {
  background-color: #218838;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
  color: vars.$azul-galicia; /* Color del icono */
  transition: color 0.2s ease-in-out;
}

.logout-button:hover {
  color: red; /* Color cuando pasas el ratón */
}

.boton-gestionar-suscripcion,
.boton-acceso {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
}

.boton-crearcuenta {
  background-color: rgb(77, 77, 255);
  color: white;
  padding: 10px 30px; /* Incrementa el padding horizontal */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Ajusta el tamaño del texto */
  white-space: nowrap; /* Evita que el texto se divida */
  overflow: hidden;
  text-align: center; /* Centra el texto dentro del botón */
  min-width: 150px; /* Asegura un ancho mínimo para el botón */
  transition: background-color 0.2s ease-in-out;
  margin-left: 10px;
  margin-top: 0px;

  @media (max-width: 960px) {
    margin-left: 0px;
    margin-top: 10px;
    white-space: normal;
  }

}

.boton-gestionar-suscripcion:hover,
.boton-acceso:hover {
  background-color: #218838;
}

.boton-crearcuenta:hover {
  background-color: blue;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Espaciado entre botones */
  margin-top: 20px;
}

.boton-acceder {
  background-color: #28a745; /* Verde */
  color: white;
  padding: 10px 30px; /* Incrementa el padding horizontal */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Ajusta el tamaño del texto */
  white-space: nowrap; /* Evita que el texto se divida */
  overflow: hidden;
  text-align: center; /* Centra el texto dentro del botón */
  min-width: 150px; /* Asegura un ancho mínimo para el botón */
  transition: background-color 0.2s ease-in-out;
  margin-left: 10px;
  margin-top: 0px;

  @media (max-width: 960px) {
    margin-left: 0px;
    //margin-top: 10px;
    white-space: normal;
  }

}

.boton-acceder:hover {
  background-color: #218838;
}

.boton-cancelar {
  background-color: #6c757d; /* Gris */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
  transition: background-color 0.2s ease-in-out;
}

.boton-cancelar:hover {
  background-color: #5a6268;
}

.boton-darse-baja {
  background-color: #dc3545; /* Rojo */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
  transition: background-color 0.2s ease-in-out;
}

.boton-darse-baja:hover {
  background-color: #c82333;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px){
    flex-direction: column;
    gap: 10px;
  }

}
