@use "./variables.scss" as vars; // Importa variables

textarea {
  border: 1px solid vars.$teal;
  background-color: transparent;
  font-size: 1rem;
  height: 100px;
  outline: none;
  padding: 10px;
}

.select-element {
  border: 1px solid #ccc;
  font-size: 1.1rem;
  color: #42454a;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  background-color: transparent;
  outline: none;
}
