@use "./variables.scss" as vars;

/* Blog*/

.blog {
  padding-right: 15%;
  padding-left: 15%;
  .ultima-entrada {
    h1,
    p {
      color: white;
    }
  }

  .ultima-entrada {
    h1 {
      margin-top: 0.2rem; /* Reduce el espacio superior del título */
    }
    .fecha-entrada-blog {
      color: vars.$azul-galicia;
      margin-bottom: 0.2rem; /* Reduce el espacio inferior de la fecha */
    }
  }

  .lista-entradas .entrada p {
    display: -webkit-box; /* Necesario para habilitar line-clamp en navegadores WebKit */
    -webkit-line-clamp: 2; /* Prefijo WebKit */
    line-clamp: 2; /* Propiedad estándar */
    -webkit-box-orient: vertical; /* Orientación para line-clamp */
    overflow: hidden; /* Esconde el texto que exceda el límite */
    text-overflow: ellipsis; /* Añade los puntos suspensivos (...) */
    white-space: normal; /* Permite líneas múltiples */
  }
}

/* Blog Header */

.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: white;
    font-size: 1.4em;
  }
  .nav-list {
    display: flex;
    .blog-header-link {
      margin-left: 15px;
      font-size: 1.2em;
      a {
        color: white;
        text-decoration: none;
        &:hover {
          color: #ccc; // Cambia el color al pasar el cursor.
        }
        &:active {
          color: #999; // Cambia el color al hacer clic.
        }
      }
    }
    .active {
      a {
      color: vars.$azul-galicia !important;
      }
    }
  }
}

/* Blog Description */

.blog-description {
  h1 {
    color: white;
    font-size: 2.6em;
    display: flex;
    justify-content: center;
    font-weight: bold; // Asegura que el texto sea grueso
    margin: 20px 0; // Espaciado vertical para el texto

    // Añade líneas superior e inferior
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 10px 0; // Espaciado entre el texto y las líneas

    @media (max-width: 960px) {
      font-size: 2em;
    }

    @media (max-width: 600px) {
      font-size: 1.8gitem;
    }

  }
  p {
    color: #ccc;
    font-size: 1.25em;
  }
  .blog-image-header {
    width: 100%; /* Asegúrate de que el contenedor ocupe el ancho completo */
    height: 400px; /* Ajusta esta altura según necesites */
    overflow: hidden; /* Previene desbordamiento */
    img {
      width: 100%; /* La imagen ocupará el ancho completo del contenedor */
      height: auto; /* Mantiene la proporción de la imagen */
      object-fit: cover; /* Recorta la imagen para que encaje perfectamente en el contenedor */
      display: block; /* Evita espacio blanco en algunos navegadores */
    }
  }
}

/* Blog Última Entrada */

.blog {
  .ultima-entrada {
    h1 {
      margin-top: 0.2rem; /* Reduce el espacio superior del título */
    }
    .fecha-entrada-blog {
      color: vars.$azul-galicia;
      margin-bottom: 0.2rem; /* Reduce el espacio inferior de la fecha */
    }
  }
}

/* Blog Entradas Anteriores */

.blog {
  .custom-divider {
    border: 0;
    height: 1px;
    background: linear-gradient(to right, #ccc, #515151, #ccc);
    margin: 60px 0 20px 0;
  }

  h3 {
    color: white !important;
    margin-top: 3rem;
  }

  .lista-entradas {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas de igual tamaño */
    grid-template-rows: repeat(2, auto); /* 2 filas con altura automática */
    gap: 20px; /* Espaciado entre las entradas (ajústalo según prefieras) */
    width: 100%; /* Asegura que el grid ocupe todo el ancho disponible */


    @media (max-width: 960px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 720px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .entrada {
      min-width: 0; /* Evita que el contenido expanda las columnas */
      word-wrap: break-word; /* Asegura que el texto largo se ajuste */
      h2,
      p {
        color: white !important;
        margin-bottom: 0.2rem;
      }

      .fecha {
        color: vars.$azul-galicia !important;
      }

      .titulo {
        display: flex;
        justify-content: space-between;
        margin-top: 0.2rem;
        align-items: flex-start; /* Alinea los elementos al inicio del eje vertical */

        .rotated-arrow {
          transform: rotate(315deg);
          font-size: 0.8em;
          margin-top: 10px; /* Ajusta la posición para alinearse mejor con el texto */
          margin-left: 2px;
        }
      }
    }
  }
}

/* PAGINATION */

.blog {
  .cargar-mas {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .cargar-mas:hover {
    background-color: #0056b3;
  }
}

.draft-content {
  .DraftEditor-root {
    background-color: transparent;
    border: none;
    color: white;
    padding: 0;
  }
}

.resumen {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número máximo de líneas a mostrar */
  line-clamp: 2; /* Propiedad estándar */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Asegura que el texto no se trunque en una sola línea */
}
