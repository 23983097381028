.entrada-detalle {
    padding: 0% 15%;
    color: white;
  
    h1 {
      font-size: 2em;
      margin-bottom: 10px;
    }
  
    .entrada-fecha {
      color: #3ebeff; /* Color destacado para la fecha */
      margin-bottom: 20px;
    }
  
    .entrada-resumen {
      line-height: 1.6;
      font-size: 1.2em;
    }
  }
  