@use "./mixins.scss" as mixins;

.admin-page-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 60vh;
  width: 100%;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  .left-column {
    background-size: cover;

    @media (max-width: 960px) {
      display: none;
    }
  }

  .right-column {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;

    .admin-wrapper {
      width: 60%;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        font-size: 1.5rem;
        padding-bottom: 20px;
      }

      .error-autenticacion {
        color: #999999;
        padding-bottom: 20px;
      }

      @media (max-width: 960px) {
        width: 70%;
      }

      @media (max-width: 768px) {
        width: 80%;
      }
    }

    .admin-form-wrapper {
      @include mixins.base-grid();
      grid-gap: 21px;

      .form-group {
        display: flex;
        align-items: center; /* Aligns items vertically */

        svg {
          font-size: 2em;
          color: #999999;
          margin-right: 15px;
        }

        input {
          font-size: 0.85rem;
          border-radius: 5px;
          background-color: white;
          //padding: revert;
          padding: 10px 5px;
          width: 100%;
        }
      }

      .entrar {
        cursor: pointer;
        height: 42px;
        font-weight: 500;
        border: 1px solid transparent;
        transition: all 0.5s ease-in-out;
        background-color: black;
        border: 1px solid #999999;
        border-radius: 5px;
        color: #cfcfcf;
        font-size: 1.3rem;
        margin-top: 20px;
      }
    }
  }
}
