@use "./variables.scss" as vars;

.side-nav-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  background-color: black;
  padding-bottom: 10vh;
  height: 60vh;

  @media (max-width: 610px) {
    padding: 10px;
  }

  .nav-link-wrapper {
    display: flex;
    transition: color 0.5s ease-in-out; /* Transición general */
    font-size: 1.3rem;
    color: #ffffff;

    @media (max-width: 610px) {
      font-size: 1rem;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      text-decoration: none;

      span,
      svg {
        transition: color 0.5s ease-in-out, fill 0.5s ease-in-out; /* Añadir transición para fill */
      }

      svg {
        fill: #ffffff; /* Asegura que el color inicial del SVG sea blanco */
      }

      &:hover {
        transition: color 0.5s ease-in-out;
        color: vars.$azul-galicia; /* Cambia el color del texto */
        
        span,
        svg {
          color: vars.$azul-galicia;
          fill: vars.$azul-galicia; /* Cambia también el color de los SVG */
        }
      }
    }

    &:hover {
      transition: color 0.5s ease-in-out;
      color: vars.$azul-galicia;
    }
  }

  .upper-side {
    padding-bottom: 5px;
  }

  .lower-side {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }

  .portfolio-link,
  .admin {
    margin-bottom: 10px;
    font-size: 1.1rem;

    @media (max-width: 610px) {
      font-size: 1rem;
    }
  }

  .icon-wrapper {
    margin-right: 5px;
  }

  .admin {
    align-items: center;
    color: vars.$azul-galicia;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: vars.$azul-galicia !important;

      span,
      svg {
        transition: color 0.5s ease-in-out, fill 0.5s ease-in-out;
      }

      svg {
        fill: vars.$azul-galicia;
      }

      span {
        margin-right: 5px;
      }
    }

    &:hover {
      transition: color 0.5s ease-in-out;
      color: vars.$azul-galicia;
    }
  }
}

.nav-link-active {
  font-weight: bold; /* Opcional: Resalta el texto */
  border-left: 4px solid vars.$azul-galicia; /* Indicador visual en el lado izquierdo */
  background-color: rgba(0, 0, 255, 0.1); /* Fondo opcional */
}
