@use "./variables.scss" as vars; // Importa variables

body {
  background-color: black;
  margin: 0px;
  font-family: "Titillium Web", sans-serif;
}

.app-wrapper {
  background-color: black;
}

.container-wrapper {

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .container-upper-side {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  .container-lower-side {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: black;
    flex-shrink: 0;

    .container-lower-left-side {
      padding-left: 2.5%;
    }

    .container-lower-right-side {
      width: 100%;
      flex: 1;
    }

  }
  
}

.footer {
  flex-shrink: 0;
}

.content {
  flex: 1;
}

.azul-galicia{
  color: vars.$azul-galicia;
}
